
import { FormPage } from '@/mixins'
import { BusinessBranchModel, BusinessBranchStatus } from 'tradingmate-modules/src/models/api/businesses'
import StripePortalCreateOptionsModel from 'tradingmate-modules/src/models/api/payment/stripe/checkout/StripePortalCreateOptionsModel'
import { UserModel } from 'tradingmate-modules/src/models/api/users'
import UpdatePasswordModel from 'tradingmate-modules/src/models/api/users/UpdatePasswordModel'
import UpdateUserModel from 'tradingmate-modules/src/models/api/users/UpdateUserModel'
import { HttpError, Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'
import { InputText } from '@/components/inputs'

@Component({
  components: {
    InputText
  }
})
export default class DashboardProfile extends Mixins(FormPage) {
  // private user: UserModel | null = null;
  private updateUserModel: UpdateUserModel | null = null;
  private updatePasswordModel: UpdatePasswordModel = {
    NewPassword: '',
    NewPasswordConfirmation: ''
  }

  private branchStatus: BusinessBranchStatus = this.$store.state.identity.BusinessBranch.Status

  get user (): UserModel { return this.$store.state.identity.User }

  private userUpdatedSuccess = false;

  private businessBranches: BusinessBranchModel[] = [];

  mounted (): void {
    // this.user = this.$store.state.identity.User
    this.mapUserToUserUpdateModel()
    this.loading = true

    Services.API.Users.GetBusinessBranches().then((branches) => {
      this.businessBranches = branches
    }).catch((error) => {
      this.httpError = error
    }).finally(() => { this.loading = false })
  }

  mapUserToUserUpdateModel (): void {
    if (this.user === null) return
    this.updateUserModel = {
      FirstName: this.user.FirstName,
      LastName: this.user.LastName,
      Email: this.user.Email
    }
  }

  private updatingUser = false;
  updateUser (): void {
    if (this.user == null) return
    if (this.updateUserModel == null) return
    this.httpError = null
    this.userUpdatedSuccess = false
    this.updatingUser = true
    Services.API.Users.UpdateUser(this.user.UserId, this.updateUserModel)
      .then((returnUser: UserModel) => {
        this.$store.state.identity.User = returnUser
        this.mapUserToUserUpdateModel()
        this.userUpdatedSuccess = true
      }).catch((error: HttpError) => {
        this.httpError = error
      }).finally(() => { this.updatingUser = false })
  }

  private updatingPassword = false
  private passwordUpdatedSuccess = false;
  updatePassword (): void {
    this.updatingPassword = true
    this.passwordUpdatedSuccess = false
    this.httpError = null
    if (this.user == null) return
    Services.API.Users.UpdatePassword(this.user.UserId, this.updatePasswordModel)
      .then((result: boolean) => { this.passwordUpdatedSuccess = result })
      .catch((error: HttpError) => {
        this.httpError = error
      }).finally(() => { this.updatingPassword = false })
  }

  ManageMembership (): void {
    const branchId = this.$store.state.identity.BusinessBranch.BusinessBranchId

    const portalCreateOptionsModel: StripePortalCreateOptionsModel = { ReturnUrl: location.href }
    Services.API.Payment.CreatePortalSession(branchId, portalCreateOptionsModel).then((portalSession) => {
      window.open(portalSession.SessionUrl)
    })
  }
}
